import React from "react";

function Banner({ header }) {
  return (
    <div className="technologies-header ">
      <div>
        <div className="container-fix">
          <div className="head-comman-banner">
            <h2>{header}</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Banner;
