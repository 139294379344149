import React from "react";
import ListSecOne from "./ListSecOne";
import ListSecTwo from "./ListSecTwo";
import ListSecThree from "./ListSecThree";
import "./listportfolio.scss";
function ListMain() {
  return (
    <div className="home-page">
      <section>
        <ListSecOne/>
      </section>
      <section>
        <ListSecTwo/>
      </section>
      <section>
        <ListSecThree/>
      </section>
    </div>
  );
}
export default ListMain;
