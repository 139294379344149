import React from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../Components/Image";
import dnPhone from "./listportfolio-assets/dnPhone.png";

const ListSecThree = () => {
  const navigate = useNavigate();

  const goToMain = () => {
    navigate("/portfolio/daynight");
  };

  return (
    <div className="dnListBg">
      <div className="dnListSecOne">
        <div>
          <div className="row">
            <div className="col-md-6">
              <div className="dn-secPart-list">
                <p className="dn-anim-text">
                  DAY NIGHT CONNECT
                  <br />
                  DAY NIGHT CONNECT
                  <br />
                  DAY NIGHT CONNECT
                  <br />
                  DAY NIGHT CONNECT
                  <br />
                  DAY NIGHT CONNECT
                  <br />
                  DAY NIGHT CONNECT
                  <br />
                  DAY NIGHT CONNECT
                  <br />
                  DAY NIGHT CONNECT
                  <br />
                  DAY NIGHT CONNECT
                  <br />
                  DAY NIGHT CONNECT
                  <br />
                  DAY NIGHT CONNECT
                  <br />
                  DAY NIGHT CONNECT
                  <br />
                  DAY NIGHT CONNECT
                </p>
                <div
                  className="d-flex justify-content-center"
                  style={{ rotate: "90deg", position: "absolute", alignSelf: "center" }}
                >
                  <div className="container dnPhone">
                    <Image className="dnPhoneImg" src={dnPhone} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="dn-firPart-list container">
                  <h1 className="dn-title-list">
                    DAY NIGHT
                    <br /> CONNECT
                  </h1>
                  <p className="dn-desc-list">
                    We take a consultative, customer-first approach to offer solutions ranging from
                    standalone bespoke development and managed services, to building connected
                    ecosystems
                  </p>
                  <button class="learn-more-dn" onClick={goToMain}>
                    <span class="circle" aria-hidden="true">
                      <span class="icon arrow"></span>
                    </span>
                    <span class="button-text">Learn More</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="business">
        <p className="businessImg">BUSINESS UTILITY APP</p>
      </div>
    </div>
  );
};

export default ListSecThree;
