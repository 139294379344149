import React from "react";
import Image from "../Image";

const RightSideImage = (props) => {
    return (
      <div className="SecTwounitymartMain">
        <div className="SecTwounitymart-inn">
          <div className="container-fix">
            <div className="whoWeAreSec">
              <div className="gridWhoWeAre whoWeAreSecOne">
                <div className="image">
                  <Image src={props.image} alt="Who we are" title="Who we are" />
                </div>
                <div className="content">
                  <h2>{props.title}</h2>
                  <p>
                    Unity Mart is a tool for grocery shopping that makes is simple and quick for
                    clints to shop online and buy good from the comforts of their unitymarts workplaces or
                    chairs. it offer the very finest online grocery shopping experience.
                    <br />
                    The unity marketplace application features several high quality widget designs and
                    structure,which helps it to draw in more customers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default RightSideImage;
