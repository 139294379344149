import React from 'react'
import DaynightBanner from "./daynightBanner"
import DaynightSecTwo from "./daynightSecTwo"
import DaynightSecThree from "./daynightSecThree"
import "./daynight.scss"

const DaynightMain = () => {
  return (
    <div>
      <div>
        <DaynightBanner/>
      </div>
      <div>
        <DaynightSecTwo/>
      </div>
      <div>
        <DaynightSecThree/>
      </div>
    </div>
  )
}

export default DaynightMain;
