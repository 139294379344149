import React from "react";
import Image from "./../../Components/Image";
import { ReactComponent as Arrow } from "./unitymart-assets/aroowBanner.svg";
import { Link } from "react-router-dom";
import PhoneBg from "./unitymart-assets/phone-bg.png";
import Vegetables from "./unitymart-assets/vegetables.png";
import PlayStore from "./unitymart-assets/play-store.png";
import AppStore from "./unitymart-assets/app-store.png";
function Banner() {
  return (
    <div className="unitymartBg">
      <div className="row">
        <div className="col-md-6">
          <div className="bgImg">
            <Image className="phoneBg" src={PhoneBg} />
          </div>
        </div>
        <div className="col-md-6">
          <div className="vegImg">
            <Image className="vegImgimg" src={Vegetables} />
          </div>
          <div className="secPart">
            <h1 className="title">𝐔𝐍𝐈𝐓𝐘𝐌𝐀𝐑𝐓</h1>
            <p className="desc">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur ducimus quae
              reiciendis, tenetur totam atque, alias dolor facere sed dicta quod aliquid, eligendi
              est nam soluta voluptates veritatis earum modi labore id? Minima repudiandae,
              quibusdam dicta repellat autem odio nam minus architecto ex, dolores, nulla nemo? Sint
              alias dolore dolor velit reiciendis et obcaecati minima sed, eius laboriosam quas sunt
              rerum quod tenetur id maxime necessitatibus molestiae beatae minus explicabo! Beatae
              fuga similique, quibusdam delectus maiores aspernatur, repellat laudantium perferendis
              illum illo dolores sequi, excepturi cumque earum pariatur optio corrupti doloremque
              laborum facilis eum ducimus veritatis nulla? Perferendis ipsa quo, velit eum mollitia
              temporibus aliquam error tenetur labore animi nisi odit assumenda! Sunt soluta, id est
              ad voluptatem reiciendis alias, error repudiandae recusandae nihil dolorum et animi,
              omnis fugiat. Et animi doloremque qui architecto ab aliquid id, perspiciatis sit
              fugiat non. Necessitatibus mollitia et, quibusdam consequatur ipsam earum dolorum
              ullam.
            </p>
            <div className="appImg row">
              <div className="appImg-play">
                <Image className="play-store" src={PlayStore} />
              </div>
              <div className="appImg-app">
                <Image className="app-store" src={AppStore} />
              </div>
            </div>
          </div>
          <div className="card card-service">
        <div className="row">
          <p className="serv-title">
            Country
            <br />
            <span className="serv-text">India</span>
          </p>
          <p className="serv-title">
            Services <br/>
            <span className="serv-text">Mobile Devlopment,</span>
            <br />
            <span className="serv-text">Ui/UX Design</span>
            <br />
            <span className="serv-text">Backend Devlopment</span>
          </p>
          <p className="serv-title">
            Industry
            <br />
            <span className="serv-text">E-commerce</span>
          </p>
        </div>
      </div>
        </div>
      </div>
    </div>
  );
}
export default Banner;
