import React from "react";
import LeftSideImage from "../../Components/PortfolioComponents/LeftSideImage";
import RightSideImage from "../../Components/PortfolioComponents/RightSideImage";
import AboutImage from "./daynight-assets/dnPhone1.png";
import Phone2 from "./daynight-assets/dnPhone2.png";
import Phone3 from "./daynight-assets/dnPhone3.png";
import Phone4 from "./daynight-assets/dnPhone4.png";
import Phone5 from "./daynight-assets/dnPhone5.png";
import Phone6 from "./daynight-assets/dnPhone6.png";
import Phone7 from "./daynight-assets/dnPhone7.png";

function DaynightSecTwo() {
  return (
    <>
      <RightSideImage image={AboutImage} title={"Who we are"} desc={""} />
      <LeftSideImage image={Phone2} title={"What we do"} />
      <RightSideImage image={Phone3} title={"Who we are"} />
      <LeftSideImage image={Phone4} title={"What we do"} />
      <RightSideImage image={Phone5} title={"Who we are"} />
      <LeftSideImage image={Phone6} title={"What we do"} />
      <RightSideImage image={Phone7} title={"Who we are"} />
    </>
  );
}
export default DaynightSecTwo;
