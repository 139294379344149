import React from "react";
import AboutImage from "./unitymart-assets/phone1.png";
import Phone2 from "./unitymart-assets/phone2.png";
import Phone3 from "./unitymart-assets/phone3.png";
import Phone4 from "./unitymart-assets/phone4.png";
import Phone5 from "./unitymart-assets/phone5.png";
import Phone6 from "./unitymart-assets/phone6.png";
import Phone7 from "./unitymart-assets/phone7.png";
import RightSideImage from "../../Components/PortfolioComponents/RightSideImage";
import LeftSideImage from "../../Components/PortfolioComponents/LeftSideImage";

function SecTwounitymart() {
  return (
    <>
      <RightSideImage image={AboutImage} title={"Who we are"} desc={""} />
      <LeftSideImage image={Phone2} title={"What we do"} />
      <RightSideImage image={Phone3} title={"Who we are"} />
      <LeftSideImage image={Phone4} title={"What we do"} />
      <RightSideImage image={Phone5} title={"Who we are"} />
      <LeftSideImage image={Phone6} title={"What we do"} />
      <RightSideImage image={Phone7} title={"Who we are"} />
    </>
  );
}

// const RightSideImage = (props) => {
//   return (
//     <div className="SecTwounitymartMain">
//       <div className="SecTwounitymart-inn">
//         <div className="container-fix">
//           <div className="whoWeAreSec">
//             <div className="gridWhoWeAre whoWeAreSecOne">
//               <div className="image">
//                 <Image src={props.image} alt="Who we are" title="Who we are" />
//               </div>
//               <div className="content">
//                 <h2>{props.title}</h2>
//                 <p>
//                   Unity Mart is a tool for grocery shopping that makes is simple and quick for
//                   clints to shop online and buy good from the comforts of their unitymarts workplaces or
//                   chairs. it offer the very finest online grocery shopping experience.
//                   <br />
//                   The unity marketplace application features several high quality widget designs and
//                   structure,which helps it to draw in more customers.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const LeftSideImage = (props) => {
//   return (
//     <div className="SecTwounitymartMain">
//       <div className="SecTwounitymart-inn">
//         <div className="container-fix">
//           <div className="whoWeAreSec">
//             <div className="gridWhoWeAre whoWeAreSecTwo">
//               <div className="content">
//                 <h2 style={{textAlign:"left"}}>What we do</h2>
//                 <p style={{textAlign:"left"}}>
//                   Unity Mart is a tool for grocery shopping that makes is simple and quick for
//                   clints to shop online and buy good from the comforts of their unitymarts workplaces or
//                   chairs. it offer the very finest online grocery shopping experience.
//                   <br />
//                   The unity marketplace application features several high quality widget designs and
//                   structure,which helps it to draw in more customers.
//                 </p>
//               </div>
//               <div className="image">
//                 <Image src={props.image} alt="Who we are" title="Who we are" />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default SecTwounitymart;
