import Android from "./assets/android.svg";
import Kotlin from "./assets/kotlin.svg";
import Java from "./assets/java.svg";
import IOS from "./assets/ios.svg";
import Swift from "./assets/swift.svg";
import ReactNative from "./assets/reactnative.svg";
import Flutter from "./assets/flutter.svg";

import Angular from "./assets/angular.svg";
import Vue from "./assets/vue.svg";
import Next from "./assets/next.svg";
import HTML from "./assets/html.svg";
import CSS from "./assets/css.svg";
import Javascript from "./assets/javascript.svg";

import PHP from "./assets/php.svg";
import CodeIgniter from "./assets/codeigniter.svg";
import Express from "./assets/express.svg";
import Laravel from "./assets/laravel.svg";
import NodeJs from "./assets/nodejs.svg";
import NestJs from "./assets/nestjs.svg";
import Go from "./assets/go.svg";
import Python from "./assets/python.svg";
import Django from "./assets/django.svg";
import Flask from "./assets/flask.svg";
import Fastapi from "./assets/fastapi.svg";

import AWS from "./assets/aws.svg";
import Firebase from "./assets/firebase.svg";
import DigitalOcean from "./assets/digitalocean.svg";
import GoogleCloud from "./assets/googlecloud.svg";

import Figma from "./assets/figma.svg";
import Sketch from "./assets/sketch.svg";
import AdobeXD from "./assets/adobexd.svg";
import AdobePhotoshop from "./assets/adobeps.svg";
import AdobeIllustrator from "./assets/adobeIllustrator.svg";
import AdobeAfterEffects from "./assets/adobeaftereffects.svg";
import MockFlow from "./assets/mockflow.svg";
import Blender from "./assets/blender.svg";

import Kubernets from "./assets/kubernets.svg";
import Jenkins from "./assets/jenkins.svg";
import Fastlane from "./assets/fastlane.svg";
import Docker from "./assets/docker.svg";
import Ansible from "./assets/ansible.svg";

import MySQL from "./assets/mysql.svg";
import MsSQL from "./assets/msql.svg";
import Postgresql from "./assets/postgresql.svg";
import MongoDB from "./assets/mongodb.svg";
import Realm from "./assets/realm.svg";
import SQLLite from "./assets/sqlite.svg";
import Unity3D from "./assets/unity.svg";
import Ruby from "./assets/ruby.svg";
// import Rust from "./assets/rust.svg";
import c from "./assets/c++.svg";
import cpp from "./assets/c.svg";

const technologiesData = [
  {
    header: "Backend Technologies",
    list: [
      { title: "PHP", icon: PHP },
      { title: "Code Igniter", icon: CodeIgniter },
      { title: "Laravel", icon: Laravel },
      { title: "Node Js", icon: NodeJs },
      { title: "Express", icon: Express },
      { title: "Nest Js", icon: NestJs },
      { title: "Go", icon: Go },
      { title: "Java", icon: Java },
      { title: "Python", icon: Python },
      { title: "Django", icon: Django },
      { title: "Flask", icon: Flask },
      { title: "Fast API", icon: Fastapi },
    ],
  },
  {
    header: "Frontend Technologies",
    list: [
      { title: "HTML", icon: HTML },
      { title: "CSS", icon: CSS },
      { title: "Javascript", icon: Javascript },
      { title: "React", icon: ReactNative },
      { title: "Angular", icon: Angular },
      { title: "Vue", icon: Vue },
      { title: "Next", icon: Next },
    ],
  },
  {
    header: "Mobile Technologies",
    list: [
      { title: "Android", icon: Android },
      { title: "Kotlin", icon: Kotlin },
      { title: "Java", icon: Java },
      { title: "IOS", icon: IOS },
      { title: "Swift", icon: Swift },
      { title: "Objective C", icon: IOS },
      { title: "React Native", icon: ReactNative },
      { title: "Flutter", icon: Flutter },
    ],
  },
  {
    header: "Cloud Technologies",
    list: [
      { title: "AWS", icon: AWS },
      { title: "Firebase", icon: Firebase },
      { title: "Digital Ocean", icon: DigitalOcean },
      { title: "Google Cloud", icon: GoogleCloud },
    ],
  },
  {
    header: "UI/UX & Graphics Technologies",
    list: [
      { title: "Figma", icon: Figma },
      { title: "Sketch", icon: Sketch },
      { title: "Adobe XD", icon: AdobeXD },
      { title: "Adobe Photoshop", icon: AdobePhotoshop },
      { title: "Adobe Illustrator", icon: AdobeIllustrator },
      { title: "Adobe After Effects", icon: AdobeAfterEffects },
      { title: "Mock Flow", icon: MockFlow },
      { title: "Blender", icon: Blender },
    ],
  },
  {
    header: "Devops Technologies",
    list: [
      { title: "AWS", icon: AWS },
      { title: "Kubernets", icon: Kubernets },
      { title: "Jenkins", icon: Jenkins },
      { title: "CI/CD", icon: "" },
      { title: "Fastlane", icon: Fastlane },
      { title: "Docker", icon: Docker },
      { title: "Ansible", icon: Ansible },
    ],
  },
  {
    header: "Database Technologies",
    list: [
      { title: "My SQL", icon: MySQL },
      { title: "MS SQL", icon: MsSQL },
      { title: "Postgresql", icon: Postgresql },
      { title: "MongoDB", icon: MongoDB },
      { title: "Real M", icon: Realm },
      { title: "SQLite", icon: SQLLite },
    ],
  },
  {
    header: "AR/VR",
    list: [
      { title: "React VR", icon: ReactNative },
      { title: "Unity 3D", icon: Unity3D },
    ],
  },
  {
    header: "AI/ML",
    list: [
      { title: "Python", icon: Python },
      { title: "Ruby", icon: Ruby },
    ],
  },
  {
    header: "IOT/Embedded",
    list: [
      { title: "Go Lang", icon: Go },
      { title: "Rust", icon: "" },
      { title: "Python", icon: Python },
      { title: "C", icon: cpp },
      { title: "C++", icon: c },
    ],
  },
];

export default technologiesData;
