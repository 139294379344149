import React from "react";

function Banner() {
  return (
    <div className="banner-comman">
      <div className="innerBanner-comman">
        <div className="container-fix">
          <div className="head-comman-banner">
            <h1>CRM</h1>
            <p>Customer Relationship Management</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Banner;
