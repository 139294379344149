import Placeholder from "./CompImages/placeholder.png";
function Image({ src = "", alt = "", title = "", className = "", id = "" }) {
  return (
    <img
      src={src === "" ? { Placeholder } : src}
      className={className === "" ? "" : className}
      alt={alt === "" ? document.title : alt}
      title={title === "" ? document.title : title}
      id={id === "" ? "" : id}
      width="100%"
      height="100%"
    />
  );
}
export default Image;
