import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ServiceDetails from "./pages/serviceDetails";
const Index = React.lazy(() => import("./general/index"));
const Home = React.lazy(() => import("./pages/Home"));
const Contect = React.lazy(() => import("./pages/contect"));
const Team = React.lazy(() => import("./pages/team"));
const Service = React.lazy(() => import("./pages/service"));

import Unitymart from "./pages/Portfolio/Unitymart/main";
import Daynight from "./pages/Portfolio/Daynight/daynightMain";
import ListPortfolio from "./pages/Portfolio/ListPortfolio/ListMain";

import "bootstrap/dist/css/bootstrap.min.css";
import Technologies from "./pages/technologies";


function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <React.Suspense>
            <Index Comp={Home} />
          </React.Suspense>
        }
      />
      <Route
        path="/contact-us"
        element={
          <React.Suspense>
            <Index Comp={Contect} />
          </React.Suspense>
        }
      />
      <Route
        path="/team"
        element={
          <React.Suspense>
            <Index Comp={Team} />
          </React.Suspense>
        }
      />
      <Route
        path="/services"
        element={
          <React.Suspense>
            <Index Comp={Service} />
          </React.Suspense>
        }
      />
      <Route
        path="/services-details"
        element={
          <React.Suspense>
            <Index Comp={ServiceDetails} />
          </React.Suspense>
        }
      />
      <Route
        path="/technologies"
        element={
          <React.Suspense>
            <Index Comp={Technologies} />
          </React.Suspense>
        }
      />
      <Route
      path="/portfolio/unitymart"
      element={
        <React.Suspense>
          <Index Comp={Unitymart}/>
        </React.Suspense>
      }
      />
      <Route
      path="/portfolio/daynight"
      element={
        <React.Suspense>
          <Index Comp={Daynight}/>
        </React.Suspense>
      }
      />
      <Route
      path="/portfolio"
      element={
        <React.Suspense>
          <Index Comp={ListPortfolio}/>
        </React.Suspense>
      }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
export default App;
