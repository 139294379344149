import React from "react";
import Banner from "./banner";

import "./serviceDetails.css";

const dummyData = [
  { title: "What is CRM?" },
  { title: "What is CRM?" },
  { title: "What is CRM?" },
  { title: "What is CRM?" },
  { title: "What is CRM?" },
  { title: "What is CRM?" },
  { title: "What is CRM?" },
];

export default function ServiceDetails() {
  const renderContent = (item, index) => (
    <div
      className={`col-md-12 row ${index === 0 ? "" : "service-details-content-container"} ${
        index % 2 === 0 ? "" : "service-details-reverse"
      }`}
    >
      <div className={"col-md-6"}>
        <img
          src="https://www.cio.com/wp-content/uploads/2021/12/crm_customer-relationship-management-100752744-orig.jpg?quality=50&strip=all&w=1024"
          className={"service-details-image-container"}
        />
      </div>
      <div className={"col-md-6"}>
        <h1>{item.title}</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur ducimus quae
          reiciendis, tenetur totam atque, alias dolor facere sed dicta quod aliquid, eligendi est
          nam soluta voluptates veritatis earum modi labore id? Minima repudiandae, quibusdam dicta
          repellat autem odio nam minus architecto ex, dolores, nulla nemo? Sint alias dolore dolor
          velit reiciendis et obcaecati minima sed, eius laboriosam quas sunt rerum quod tenetur id
          maxime necessitatibus molestiae beatae minus explicabo! Beatae fuga similique, quibusdam
          delectus maiores aspernatur, repellat laudantium perferendis illum illo dolores sequi,
          excepturi cumque earum pariatur optio corrupti doloremque laborum facilis eum ducimus
          veritatis nulla? Perferendis ipsa quo, velit eum mollitia temporibus aliquam error tenetur
          labore animi nisi odit assumenda! Sunt soluta, id est ad voluptatem reiciendis alias,
          error repudiandae recusandae nihil dolorum et animi, omnis fugiat. Et animi doloremque qui
          architecto ab aliquid id, perspiciatis sit fugiat non. Necessitatibus mollitia et,
          quibusdam consequatur ipsam earum dolorum ullam.
        </p>
      </div>
    </div>
  );
  return (
    <div className={"service-details-container"}>
      <Banner />
      <div className={"container mt-5 mb-5"}>
        <div className={"row"}>{dummyData.map(renderContent)}</div>
      </div>
    </div>
  );
}
