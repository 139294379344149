import React from "react";
import Banner from "./banner";
import SecTwoHome from "./secTwoUnitymart";
import SecThreeHome from "./secThreeUnitymart";
import "./unitymart.scss";
function UnitymartMain() {
  return (
    <div className="home-page">
      <div className="homeBanner">
        <Banner />
      </div>
      <div className="SecTwoHomeMain">
        <SecTwoHome />
      </div>
      <div className="secThreeHomeMain">
        <SecThreeHome />
      </div>
    </div>
  );
}
export default UnitymartMain;