import React from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../Components/Image";
import Phone1 from "./listportfolio-assets/umBg.png";

const ListSecTwo = () => {
  const navigate = useNavigate();

  const goToMain = () => {
    navigate("/portfolio/unitymart");
  };

  return (
    <div className="listSecTwo-main">
        <div className="col-md-1">
          <div className="listSecTwo-eComTxtBg">
            <p className="listSecTwo-eComTxt">E-COMMERCE</p>
          </div>
        </div>
      <div className="row">
        <div className="listSecTwo-section">
          <div className="col-md-6">
            <div className="listSecTwo-partOne">
              <h1 className="listSecTwo-umTitle">UNITYMART</h1>
              <p className="listSecTwo-umDesc">
                We take a consultative, customer-first approach to offer solutions ranging from
                standalone bespoke development and managed services, to building connected
                ecosystems
              </p>
              <button class="learn-more-um" onClick={goToMain}>
                <span class="circle-um" aria-hidden="true">
                  <span class="icon arrow"></span>
                </span>
                <span class="button-text-um">Learn More</span>
              </button>
            </div>
          </div>
          <div className="col-md-5">
            <div className="row">
              <div className="listSecTwo-partTwo">
                <p className="listSecTwo-partTwo-animTxt">
                  UNITYMART
                  <br />
                  UNITYMART
                  <br />
                  UNITYMART
                  <br />
                  UNITYMART
                  <br />
                  UNITYMART
                  <br />
                  UNITYMART
                  <br />
                  UNITYMART
                  <br />
                  UNITYMART
                  <br />
                  UNITYMART
                  <br />
                  UNITYMART
                  <br />
                  UNITYMART
                  <br />
                  UNITYMART
                  <br />
                  UNITYMART
                </p>
                <div className="listSecTwo-partTwo-umImg">
                  <div className="circle um-circle-left" />
                  <div className="circle um-circle-right" />
                  <div className="um-main-circle">
                    <div className="listSecTwo-partTwo-umPhone">
                      <Image src={Phone1} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListSecTwo;
