import React from "react";
import Image from "../../Components/Image";
import Svg1 from "./../Unitymart/unitymart-assets/Svg1.svg";
import Svg2 from "./../Unitymart/unitymart-assets/Svg2.svg";
import Android from "./../Unitymart/unitymart-assets/android.png";
import Apple from "./../Unitymart/unitymart-assets/apple.svg";
import MySql from "./../Unitymart/unitymart-assets/database.png"
import Laravel from "./../Unitymart/unitymart-assets/backend.png"

function secThreeHome() {
  const renderComponent = (name, image, lang) => (
    <div className="d-flex justify-content-center mt-4">
      <div className="circle circle-left" />
      <div className="circle circle-right" />
      <div className="main-circle">
        <div className="row">
          <div className="icon">
            <div className="icon-circle">
              <Image className="androidIcon" src={image} />
            </div>
          </div>
          <p className="nameIcon-dn">{name}</p>
          <p className="langIcon-dn">{lang}</p>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <div>
        <h3 className="secThreeTitle-dn">Technologies</h3>
        <div>
        <div className="flagBg1">
          <Image src={Svg1} />
          </div>
          <div className="technology-container-dn">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-md-3">
                  {renderComponent("Android", Android, "React Native")}
                </div>
                <div className="col-sm-6 col-md-3">
                  {renderComponent("Ios", Apple, "React Native")}
                </div>
                <div className="col-sm-6 col-md-3">
                  {renderComponent("Database", MySql, "MySql")}
                </div>
                <div className="col-sm-6 col-md-3">
                  {renderComponent("Backend", Laravel, "laravel")}
                </div>
              </div>
            </div>
          </div>
          <div className="flagBg2">
            <Image src={Svg2} />
          </div>
        </div>
        <div className="container card-thx-main-dn">
          <div class="card card-thx-dn" style={{backgroundColor:"#ffffff"}}>
            <div class="card-body">
              <h5 class="thx-dn">Thank you</h5>
              <p className="thxText-dn">
                <b>call us today for a free consultancy:</b> 1234567890 <br />
                <span>Let us help you to build you next great idea</span>
              </p>
            </div>
            <div className="card-body">
              <button class="btnTalk-dn">Let's talk</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default secThreeHome;
