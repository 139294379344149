import React from "react";
import Banner from "./banner";
import technologiesData from "./data";

import Tilt from "react-parallax-tilt";

// import "./demo.scss";
import "./Technologies.css";

export default function Technologies() {
  function randomNumber(min, max) {
    return Math.random() * (max - min) + min;
  }

  function getTitle(title) {
    let num = Math.floor(Math.random() * (4 - 0) + 0);
    const descriptions = [
      `We can help and develop your software with the help of ${title}`,
      `Using ${title}, we can assist you and create your programme.`,
      `With the aid of ${title}, we are able to assist and create your software.`,
      `Using ${title}, we can assist you and develop your software.`,
      `Utilizing ${title}, we are able to assist and develop your applications.`,
    ];
    console.log("🚀 => getTitle => descriptions[num]", num);
    return descriptions[num];
  }

  const renderContent = (item, index) => (
    <Tilt
      className="feature feature-one mt-md-5"
      perspective={500}
      glareEnable={true}
      glareMaxOpacity={0.45}
      // scale={1.02}
    >
      <div className="inner-element">
        <h2 className="feature__title">{item.title}</h2>
        <p className="feature__desc">{getTitle(item.title)}</p>
        <img src={item.icon} alt="" className="feature__img technologies-logo" />
      </div>
    </Tilt>
    // <div className="feature feature-one mt-md-5">
    //   <h2 className="feature__title">{item.title}</h2>
    //   <p className="feature__desc">Monitors activity to identify project roadblocks</p>
    //   <img src={item.icon} alt="" className="feature__img technologies-logo" />
    // </div>
  );
  const renderGroup = (item, index) => (
    <>
      <Banner header={item.header.toUpperCase()} />
      <div className={"container mb-5"}>
        <div className={"row justify-content-evenly"}>
          {item.list.map(renderContent)}
          {/* {renderContent()} */}
        </div>
      </div>
    </>
  );
  return <div className={"service-details-container"}>{technologiesData.map(renderGroup)}</div>;
}
