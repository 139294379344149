import React from "react";
import Image from "./../../Components/Image";
import ListBg from "./listportfolio-assets/portfolioBg.png";
const ListSecOne = () => {
  return (
    <div className="listSecOne-main row">
      <div className="col-md-6">
        <div className="listSecOne-partOne container">
          <div className="">
            <h1 className="listSecOne-portfolioTxtBg">Portfolio</h1>
            <h1 className="listSecOne-portfolioTxt">Portfolio</h1>
          </div>
          <p className="listSecOne-portfolioTitle">
            <b>
              See Our Wide Range of Innovation Apps & website Award - winning App Devlopment Agency
            </b>
          </p>
          <p className="listSecOne-portfolioDesc">
            We take a consultive, customer first approch to offer solutions ranging from standalane
            bespoke devlopment and managed service, to building coonected ecosystems across the
            enterprise and devloping smart systems by levraging emerging technologies like AI,
            blockchain and hyper-automation. we help our clients move from monolithic to
            microservice architecture. our technology know-how and access to talent from across the
            globe enables organiations to leverage an agile and scalable model for business growth.
          </p>
        </div>
      </div>
      <div className="col-md-6">
        <div className="listSecOne-partTwo">
          <div className="listSecOne-ImageBg">
            <Image className="listSecOne-Image" src={ListBg} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListSecOne;
